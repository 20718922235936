export enum AuslastungWert {
  Undefiniert = -1,
  Unbekannt,
  Leer,
  Gering,
  Mittel,
  Hoch,
  SehrHoch,
  Ausgebucht,
}
