import { model } from '@zentrains/api';
import { type Auslastung } from '@/model/auslastung';
import { AuslastungWert } from '@/model/common';

export function mapExternalAuslastung(externalAuslastung: model.Auslastung | null): Auslastung | null {
  if (externalAuslastung === null) {
    return null;
  }
  return {
    ersteKlasse: mapExternalAuslastungWert(externalAuslastung.ersteKlasse),
    zweiteKlasse: mapExternalAuslastungWert(externalAuslastung.zweiteKlasse),
  };
}

export function mapExternalAuslastungWert(externalAuslastungWert: model.AuslastungWert): AuslastungWert {
  switch (externalAuslastungWert) {
    case model.AuslastungWert.AuslastungLeer:
      return AuslastungWert.Leer;
    case model.AuslastungWert.AuslastungGering:
      return AuslastungWert.Gering;
    case model.AuslastungWert.AuslastungMittel:
      return AuslastungWert.Mittel;
    case model.AuslastungWert.AuslastungHoch:
      return AuslastungWert.Hoch;
    case model.AuslastungWert.AuslastungSehrHoch:
      return AuslastungWert.SehrHoch;
    case model.AuslastungWert.AuslastungAusgebucht:
      return AuslastungWert.Ausgebucht;
    case model.AuslastungWert.AuslastungUnbekannt:
      return AuslastungWert.Unbekannt;
    case model.AuslastungWert.UNRECOGNIZED:
    default:
      return AuslastungWert.Undefiniert;
  }
}
