import { defineStore } from 'pinia';
import { prdApi, prdNotificationApi } from '@/api/prdEndpoint';
import { localApi, localNotificationApi } from '@/api/localEndpoint';
import { devApi, devNotificationApi } from '@/api/devEndpoint';
import { type Reise } from '@/model/reise';
import { mapToExternalIdentifikation } from '@/mapper/to-api/identifikation';
import { mapExternalReise } from '@/mapper/from-api/reise';
import { captureMessage } from '@sentry/vue';
import { Toast } from '@capacitor/toast';
import { PRD_HOSTNAME } from '@/api/common';
import { Capacitor } from '@capacitor/core';
import { api, notification } from '@zentrains/api';
import { Identifikation } from '@/model/identifikation';

export enum Backend {
  LOCAL,
  DEV,
  PRD,
}

export interface BackendItem {
  title: string;
  value: Backend;
}

export interface BackendState {
  selectedBackend: Backend;
}

export const allBackend: BackendItem[] = [
  {
    title: 'Lokal',
    value: Backend.LOCAL,
  },
  {
    title: 'Dev',
    value: Backend.DEV,
  },
  {
    title: 'Prod',
    value: Backend.PRD,
  },
];

export const useBackendStore = defineStore('backend', {
  state(): BackendState {
    return {
      selectedBackend:
        window.location.hostname === PRD_HOSTNAME || Capacitor.getPlatform() !== 'web' ? Backend.PRD : Backend.DEV,
    };
  },
  getters: {
    client(): api.ZenTrainsClient {
      switch (this.selectedBackend) {
        case Backend.LOCAL:
          return localApi;
        case Backend.DEV:
          return devApi;
        case Backend.PRD:
          return prdApi;
      }
    },
    notificationClient(): notification.NotificationClient {
      switch (this.selectedBackend) {
        case Backend.LOCAL:
          return localNotificationApi;
        case Backend.DEV:
          return devNotificationApi;
        case Backend.PRD:
          return prdNotificationApi;
      }
    },
  },
  actions: {
    async refreshReise(allReiseId: Identifikation[]): Promise<Reise[]> {
      const client = this.client;
      const request: api.RefreshReiseRequest = {
        allReiseId: allReiseId.map(mapToExternalIdentifikation),
        calculateUIFields: true,
        withPlanWagenreihung: true,
      };
      const reiseResponse = await client.refreshReise(request);
      const allReise: Reise[] = [];
      const allError: string[] = [];
      reiseResponse.allResult.forEach((value) => {
        if (value.error !== undefined && value.error !== '') {
          allError.push(value.error);
        } else if (value.reise !== undefined) {
          allReise.push(mapExternalReise(value.reise));
        } else {
          captureMessage('RefreshReiseResult contained neither reise nor error', 'error');
        }
      });
      if (allError.length > 0) {
        await Toast.show({
          text: `Einige Reisen konnten nicht aktualisiert werden. ${allError.join(', ')}`,
        });
      }
      return allReise;
    },
  },
  persist: true,
});
